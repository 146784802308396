import React from "react";
// 引入地区数据
import { areaList } from "@vant/area-data";

const Profile = () => {
  const navigate = React.Router.useNavigate();

  const [form] = React.Vant.Form.useForm();
  const [business, setBusiness] = React.useState(
    React.Cookies.load("business") || {}
  );
  const [RegionShow, setRegionShow] = React.useState(false);
  const [RegionCode, setRegionCode] = React.useState([
    business.province,
    business.city,
    business.district,
  ]);

  const onSubmit = async values => {
    let data = {
        id:business.id,
        nickname:values.nickname,
        email:values.email,
        gender:values.gender,
        code:business.district ? business.district : (business.city ? business.city : business.province)
    }

    if(values.password)
    {
        data.password = values.password
    }

    let avatar = values.avatar[0]?.file;

    if(avatar)
    {
        data.avatar = avatar;
    }

    let result = await React.Http.UPLOAD({
        url:'business/profile',
        params:data
    })

    if(result.code === 1)
    {
      React.Vant.Notify.show({
        type:'success',
        message:result.msg,
        duration:1500,
        onClose:() => {
          React.Cookies.save('business',result.data,{path:'/'});
          navigate(-1);
        }
      });
      return;
    }else{
      React.Vant.Notify.show({
        type:'warning',
        message:result.msg,
        duration:1500
      });
      return;
    }

  };

  const onRegionConfirm = (_,values) => {
    let [province,city,district] = values;
    
    if(!province || !city || !district)
    {
      React.Vant.Toast.info('请选择地区');
      return;
    }

    let RegionText = '';

    if(province.value)
    {
      RegionText = province.text;
      business.province = province.value
    }

    if(city.value)
    {
      RegionText += '-' + city.text;
      business.city = city.value;
    }

    if(district.value)
    {
      RegionText += '-' + district.text;
      business.district = district.value;
    }

    business.region_text = RegionText;

    setBusiness(business);

    setRegionShow(false);
  };

  const onBack = () => {
    navigate(-1);
  };

  const FormFooter = () => {
    return (
      <>
        <div style={{ margin: "16px 16px 0" }}>
          <React.Vant.Button round nativeType="submit" type="primary" block>
            提交
          </React.Vant.Button>
        </div>
      </>
    );
  };

  return (
    <>
      <React.Vant.Sticky>
        <React.Vant.NavBar
          title="修改资料"
          leftText="返回"
          onClickLeft={onBack}
        />
      </React.Vant.Sticky>

      <div className="my_kuang">
        <div className="bj_img">
          <img className="beij_s" src="/assets/images/my_beij.jpg" alt="" />
          <div className="nimetou_gaib">
            <div className="toux_hou">
              <img
                id="Dfgrg"
                style={{ height: "1.1rem" }}
                src={business.avatar_cdn}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <React.Vant.Form
        form={form}
        onFinish={onSubmit}
        footer={<FormFooter />}
        labelAlign={"right"}
        colon
      >
        <React.Vant.Form.Item
          label="昵称"
          name="nickname"
          rules={[{ required: true, message: "请填写昵称" }]}
          initialValue={business.nickname || ""}
        >
          <React.Vant.Input placeholder="请输入昵称" />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          label="邮箱"
          name="email"
          rules={[{ required: true, message: "请填写邮箱" }]}
          initialValue={business.email || ""}
        >
          <React.Vant.Input placeholder="请输入邮箱" />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item label="密码" name="password">
          <React.Vant.Input placeholder="密码为空即不修改密码" />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          label="性别"
          name="gender"
          initialValue={business.gender}
        >
          <React.Vant.Radio.Group direction="horizontal">
            <React.Vant.Radio name="0">保密</React.Vant.Radio>
            <React.Vant.Radio name="1">男</React.Vant.Radio>
            <React.Vant.Radio name="2">女</React.Vant.Radio>
          </React.Vant.Radio.Group>
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          label="选择地区"
          onClick={() => {
            setRegionShow(true);
          }}
          isLink
        >
          <React.Vant.Input
            placeholder="请选择地区"
            readOnly
            value={business.region_text}
          />
        </React.Vant.Form.Item>

        <React.Vant.Popup
          position="bottom"
          round
          visible={RegionShow}
          onClose={() => {
            setRegionShow(false);
          }}
        >
          <React.Vant.Area
            title="选择地区"
            areaList={areaList}
            value={RegionCode}
            onCancel={() => {
              setRegionShow(false);
            }}
            onConfirm={onRegionConfirm}
          />
        </React.Vant.Popup>

        <React.Vant.Form.Item
          label="头像"
          name="avatar"
          initialValue={[
            {
              url: business.avatar_cdn,
            },
          ]}
        >
          <React.Vant.Uploader maxCount={1} />
        </React.Vant.Form.Item>
      </React.Vant.Form>
    </>
  );
};

export default Profile;
