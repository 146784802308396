import React from "react";
import { areaList } from "@vant/area-data";

const Add = () => {
  const navigate = React.Router.useNavigate();
  const [form] = React.Vant.Form.useForm();
  const [product, setProduct] = React.useState(React.Cookies.load('product') || {});
  const [business, setBusiness] = React.useState(
    React.Cookies.load("business") || {}
  );
  const [RegionShow, setRegionShow] = React.useState(false);
  const [code, setCode] = React.useState([
    business.province,
    business.city,
    business.district,
  ]);
  const [DateShow, setDateShow] = React.useState(false);
  const [start, setStart] = React.useState(new Date(new Date()).getTime());
  const [end, setEnd] = React.useState(
    new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
  );
  const [day, setDay] = React.useState(10);
  const [endTime, setEndTime] = React.useState(
    `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
  );
  // 押金以及租金
  const [price, setPrice] = React.useState(0);
  const [rent, setRent] = React.useState(0);
  // 上传证件
  const [card, setCard] = React.useState([]);

  const onSubmit = async (values) => {
    if(day < 10)
    {
        React.Vant.Notify.show({
            type:'warning',
            message:'租赁时间不能小于10天',
            duration:1500
        });

        return;
    }

    let data = {
        busid:business.id,
        proid:product.id,
        nickname:values.mobile,
        mobile:values.mobile,
        code:business.district ? business.district : (business.city ? business.city : business.province),
        rent:product.rent,
        price:price,
        card:values.card[0].file,
        start:Math.floor(start / 1000),
        end:Math.floor(end / 1000),
        address:values.address
    }


    let result = await React.Http.UPLOAD({
        url:'product/lease/add',
        params:data
    });

    if(result)
    {
        if(result.code === 1)
        {
            React.Vant.Notify.show({
                type:'success',
                message:result.msg,
                duration:1500,
                onClose:() => {
                    React.Cookies.remove('product',{path:'/'});
                    navigate('/product/order/index');
                }
            });
            return;
        }else {
            React.Vant.Notify.show({
                type:'warning',
                message:result.msg,
                duration:1500
            });
            return;
        }
    }

    



  };

  const onRegionConfirm = (_,values) => {
    let [province,city,district] = values;

    if(!province || !city || !district)
    {
        React.Vant.Toast.info('请选择地区');
        return;
    }

    let region_text = '';

    if(province.value)
        {
            region_text = province.text + '-';
            business.province = province.value;
        }

        if(city.value)
        {
            region_text += city.text + '-';
            business.city = city.value;
        }

        if(district.value)
        {
            region_text += district.text;
            business.district = district.value;
        }

        business.region_text = region_text;
        setBusiness(business);
        setRegionShow(false);
  };

  const onDateConfirm = (values) => {
    let H = new Date(start).getHours();
    let I = new Date(start).getMinutes();
    let S = new Date(start).getSeconds();

    values.setHours(H,I,S);

    setEnd(values);

    setEndTime(`${values.getFullYear()}-${values.getMonth() + 1}-${values.getDate()}`)

    setDay(Math.ceil((values - start) / (60 * 60 * 24 * 1000)));
  };

  const onSelectProduct = () => {
    navigate("/product/product/index?action=lease");
  };

  const getTotal = () => 
  {
    // 一天租金
    let rent_price = product.rent_price || 0;
    let Price = day * parseFloat(rent_price);

    // 押金
    let rent = product.rent || 0;

    setPrice(Price);
    setRent(parseFloat(rent) + Price);
  }


  React.useEffect(() => {
    getTotal();
  },[day]);

  const onBack = () => {
    React.Cookies.remove('product',{path:'/'});
    navigate(-1);
  };

  return (
    <>
      <React.Vant.Sticky>
        <React.Vant.NavBar
          title="商品租赁"
          leftText="返回"
          onClickLeft={onBack}
        />
      </React.Vant.Sticky>

      {/* 表单结构 */}
      <div className="add">
        <React.Vant.Form form={form} onFinish={onSubmit}>
          {/* 选择租赁商品 */}
          <React.Vant.Form.Item
            labelWidth="4.5em"
            labelAlign="right"
            label="租赁商品"
            isLink
            onClick={onSelectProduct}
            colon
          >
            <React.Vant.Input
              placeholder="租赁商品"
              readOnly
              value={product.name ? product.name : ""}
            />
          </React.Vant.Form.Item>

          {/* 押金 */}
          <React.Vant.Form.Item
            label="押金"
            labelWidth="4.5em"
            labelAlign="right"
            colon
          >
            <React.Vant.Input
              readOnly
              value={product.rent ? product.rent : ""}
              placeholder={"押金"}
            />
          </React.Vant.Form.Item>

          {/* 日租租金 */}
          <React.Vant.Form.Item
            label="日供租金"
            labelWidth="4.5em"
            labelAlign="right"
            colon
          >
            <React.Vant.Input
              readOnly
              value={product.rent_price ? product.rent_price : ""}
              placeholder={"日供租金"}
            />
          </React.Vant.Form.Item>

          <React.Vant.Form.Item
            name="nickname"
            label="昵称"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            initialValue={business.nickname}
            rules={[{ required: true, message: "请输入昵称" }]}
          >
            <React.Vant.Input placeholder="请输入昵称" />
          </React.Vant.Form.Item>

          {/* 手机号 */}
          <React.Vant.Form.Item
            label="手机号"
            name="mobile"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            initialValue={business.mobile}
            rules={[{ required: true, message: "请输入手机号" }]}
          >
            <React.Vant.Input placeholder="请输入手机号" />
          </React.Vant.Form.Item>

          {/* 地区 */}
          <React.Vant.Form.Item
            label="地区"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            onClick={() => {
              setRegionShow(true);
            }}
            isLink
          >
            <React.Vant.Input
              placeholder="请选择地区"
              readOnly
              value={business.region_text}
            />
          </React.Vant.Form.Item>

          <React.Vant.Popup
            position="bottom"
            round
            visible={RegionShow}
            onClose={() => {
              setRegionShow(false);
            }}
          >
            <React.Vant.Area
              title="请选择地区"
              areaList={areaList}
              value={code}
              onCancel={() => {
                setRegionShow(false);
              }}
              onConfirm={onRegionConfirm}
            />
          </React.Vant.Popup>

          {/* 详细地址 */}
          <React.Vant.Form.Item
            name="address"
            label="详细地址"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            rules={[{ required: true, message: "请输入详细地址" }]}
          >
            <React.Vant.Input placeholder="请输入详细地址" />
          </React.Vant.Form.Item>

          {/* 租用时间 */}
          <React.Vant.Form.Item
            label="租用时间"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            onClick={() => {
              setDateShow(true);
            }}
            isLink
          >
            <React.Vant.Input readOnly value={endTime} />
          </React.Vant.Form.Item>

          <React.Vant.Calendar
            showConfirm={false}
            visible={DateShow}
            defaultValue={end}
            onClose={() => {
              setDateShow(false);
            }}
            onConfirm={onDateConfirm}
          />

          {/* 图片 */}
          <React.Vant.Form.Item
            label="上传证件"
            name="card"
            labelWidth="4.5em"
            labelAlign="right"
            colon
            rules={[{ required: true, message: "请上传证件图片" }]}
          >
            <React.Vant.Uploader previewSize={150} maxCount={1} value={card} />
          </React.Vant.Form.Item>

          <div className="zy_goods_foot dis_flex">
            <div className="left">
              <div>
                {day}天
                <b>
                  ￥<em>{price}</em>
                </b>
              </div>
              <div className="tou_d">含押金￥{rent}</div>
            </div>
            <p>
              <React.Vant.Button
                nativeType="submit"
                block
                size="normal"
                type="primary"
              >
                立即租赁
              </React.Vant.Button>
            </p>
          </div>
        </React.Vant.Form>
      </div>
    </>
  );
};

export default Add;
