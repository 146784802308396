import React from 'react';
// 引入当前目录里的所有js文件
const Modules = require.context('./',false,/\.js$/);

const RouterMap = [];

Modules.keys().reduce((modules, modulePath) => {
    // 路由模块名称
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/,'$1');

    if(ModuleName !== 'index')
    {
        const list = Modules(modulePath);
        // 追加到数组
        RouterMap.push(...list.default)
    }

    return RouterMap;
},{});

// 默认父组件
const layout = () => {
    return (
        <>
        {/* 显示子路由 */}
        <React.Router.Outlet />
        </>
    )
};

const RouterList = [
    {
        path:'/product',
        component:layout,
        children:RouterMap
    }
];

export default RouterList;