import { Route, Routes, Outlet } from "react-router-dom";
import AuthRouter from "./auth";
import Home from "../components/Home";

// 引入当前目录所有的js文件
const ModulesFile = require.context('./',true,/.js$/);

const RouterMap = [];

// 不需要合并总路由
const NoNeedAuth = ['index','auth'];

ModulesFile.keys().reduce((modules, modulePath) => {
  console.log(modulePath);
  // 路由模块名称 文件夹/index user/index
  const ModuleName = modulePath.replace(/^.\/(.*)\.js/,'$1');

  if(!NoNeedAuth.includes(ModuleName))
  {
    RouterMap.push(...ModulesFile(modulePath).default);
  }

  return RouterMap;

},{})

const RouterList = () => 
{
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<AuthRouter auth={false} component={<Home />} />}
        />

        <Route path="/" element={<Outlet />}>
          {RouterMap.map((item,index) => {
            return (
              <Route key={index} path={item.path} element={<AuthRouter auth={item.auth} component={<item.component />} />}>
                {item.children && item.children.map((son,idx) => {
                  return (
                    <Route key={idx} path={son.path} element={<AuthRouter auth={son.auth} component={<son.component />} />} />
                  )
                })}
              </Route>
            )
          })}
          </Route>
      </Routes>
    </>
  );
};

export default RouterList;
