import React from "react";
import Footer from '../common/Footer'

const Index = () =>
{
    const navigate = React.Router.useNavigate();

    const [business,setBusiness] = React.useState(React.Cookies.load('business') || {})

    const onLogout = () =>
    {
        React.Vant.Dialog.confirm({
            title:'退出账号',
            message:'确认退出当前账号？',
            showCancelButton:true,
            theme:'round'
        }).then(() => {
            React.Cookies.remove('business',{path:'/'});

            React.Vant.Notify.show({
                type:'success',
                message:'退出成功',
                duration:1500,
                onClose:() => {
                    
                    navigate('/business/login');
                }
            });
        }).catch((err) => {
            
        });
    }

    const Email = () => {
        if(business.email && business.auth != 1)
        {
            return (
                <>
                    <div className="my_dind">
                        <div className="bt">
                            <React.Router.NavLink to="/business/email">
                                <h3>
                                    <img src="/assets/images/my_x_01.png" />
                                    邮箱认证
                                </h3>
                                <div className="right">
                                    <img src="/assets/images/right_jiant.png" />
                                </div>
                            </React.Router.NavLink>
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <div className="my_kuang">
                <div className="bj_img">
                    <img className="beij_s" src="/assets/images/my_beij.jpg" alt="" />
                    <div className="nimetou_gaib">
                        <div className="toux_hou">
                            <img
                                id="Dfgrg"
                                style={{height:"1.1rem"}}
                                src={business.avatar_cdn}
                                alt=""
                            />
                        </div>
                        <div className="mingz">
                            <h2>{business.nickname ? business.nickname : business.mobile_text}</h2>  
                        </div>
                    </div>
                </div>
                <div className="div_bx_k">
                    <div className="neir_Ef">
                        <div className="yverjif">
                            <ul>
                                <li>
                                    <h2>0</h2>
                                    <p>文章收藏</p>
                                </li>
                                <li>
                                    <a href="my_cs.html">
                                        <h2>9</h2>
                                        <p>商品收藏</p>
                                    </a>
                                </li>
                                <li>
                                    <React.Router.NavLink to="/product/order/index">
                                        <h2>17</h2>
                                        <p>我的订单</p>
                                    </React.Router.NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="list_index_my">
                            <div className="fenh_ziyek">
                                <h3>更多服务 </h3>
                            </div>
                            <div className="my_dind">
                                <div className="bt">
                                    <React.Router.NavLink to="/business/profile">
                                        <h3>
                                            <img src="/assets/images/my_x_01.png" />
                                            修改资料
                                        </h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.Router.NavLink>
                                </div>
                            </div>

                            <Email />

                            <div className="my_dind">
                                <div className="bt">
                                    <React.Router.NavLink>
                                        <h3>
                                            <img src="/assets/images/my_x_01.png" />
                                            消费记录
                                        </h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.Router.NavLink>
                                </div>
                            </div>

                            <div className="my_dind">
                                <div className="bt">
                                    <a href="my_fk.html">
                                        <h3>
                                            <img src="/assets/images/my_x_01.png" />
                                            意见反馈
                                        </h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="my_dind">
                                <div className="bt">
                                    <a href="my_guan.html">
                                        <h3>
                                            <img src="/assets/images/my_x_02.png" />
                                            关于我们
                                        </h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="my_dind">
                                <div className="bt">
                                    <a onClick={onLogout}>
                                        <h3>
                                            <img src="/assets/images/my_x_01.png" />
                                            退出账号
                                        </h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '.9rem' }}></div>
                    </div>
                </div>
            </div>

            <div style={{ height: '.75rem' }}></div>

            <Footer />
        </>
    );
}

export default Index;