import React from "react";
import Index from "../components/business/Index";
import Login from "../components/business/Login";
import Register from "../components/business/Register";
import Profile from "../components/business/Profile";
import Email from "../components/business/Email";

const list = [
    {
        path:'index',
        name:'BusinessIndex',
        component:Index,
        auth:true
    },
    {
        path:'login',
        name:'BusinessLogin',
        component:Login,
        auth:false
    },
    {
        path:'register',
        name:'BusinessRegister',
        component:Register,
        auth:false
    },
    {
        path:'profile',
        name:'BusinessProfile',
        component:Profile,
        auth:false
    },
    {
        path:'email',
        name:'BusinessEmail',
        component:Email,
        auth:true
    }
];

const layout = () => {
    return (
        <>
        {/* 显示子级路由 */}
            <React.Router.Outlet />
        </>
    )
}

const RouterList = [
    {
        path:'/business',
        name:'Business',
        component:layout,
        children:list
    }
]

export default RouterList;