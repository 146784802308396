import Add from "../../components/product/lease/Add";


const Lease = [
    {
        path:'lease/add',
        name:'LeaseAdd',
        component:Add,
        auth:true
    }
];

export default Lease;