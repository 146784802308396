import React from "react";

const Info = () => 
{
    const navigate = React.Router.useNavigate();
    const [SearchParams] = React.Router.useSearchParams();
    const [id,setId] = React.useState(SearchParams.get('id') || 0);
    const [business,setBusiness] = React.useState(React.Cookies.load('business') || {});
    const [info,setInfo] = React.useState({});
    const [prev,setPrev] = React.useState({});
    const [next,setNext] = React.useState({});
    

    React.useEffect(() => {
        getInfoData();
    },[id]);

    const getInfoData = async () =>
    {
        let result = await React.Http.POST({
            url:'category/category/info',
            params:{
                id,
                busid:business.id
            }
        });

        if(result)
        {
            if(result.code === 0)
            {
                React.Vant.Notify.show({
                    type:'warning',
                    message:result.msg,
                    duration:1500,
                    onClose:() => {
                        navigate(-1);
                    }
                });

                return;
            }

            setInfo(result.data.info);
            setPrev(result.data.prev || {});
            setNext(result.data.next || {});
        }
    }

    const Prev = () =>
    {
        const toPrev = () =>
        {
            setId(prev.id);
            navigate('/category/category/info?id=' + prev.id);
        }

        if(prev.id)
        {
            return (
                <>
                    <p>上一篇：<a onClick={toPrev}>{prev.name}</a></p>
                </>
            )
        }else{
            return (
                <>
                    <p>上一篇：<a>到顶了</a></p>
                </>
            )
        }
    }



    const Next = () =>
    {
        const toNext = () =>
        {
            setId(next.id);
            navigate('/category/category/info?id=' + next.id);
        }

        if(next.id)
        {
            return (
                <>
                    <p>下一篇：<a onClick={toNext}>{next.name}</a></p>
                </>
            )
        }else{
            return (
                <>
                    <p>下一篇：<a>到底了</a></p>
                </>
            )
        }

    }


    const onCollection = async () => {
        if(!business || JSON.stringify(business) === '{}')
        {
            React.Vant.Toast.fail('请先登录');
            return;
        }

        let data = {
            id,
            busid:business.id
        }

        let result = await React.Http.POST({
            url:'category/category/collection',
            params: data
        })

        if(result && result.code === 1)
        {
            if(result.msg.includes('取消收藏成功'))
            {
                info.collection_status = false;
                setInfo(info);
            }else{
                info.collection_status = true;
                setInfo(info);
            }
            navigate(0);
        }else{

        }
    }

    const onBack = () =>
    {   // 直接返回主页
        navigate('/category/category/index');
    }

    return (
        <>
            <React.Vant.Sticky>
                <React.Vant.NavBar
                    title="学术详情"
                    leftText="返回"
                    onClickLeft={onBack}
                />
            </React.Vant.Sticky>

            <div className="xs_Xiangq">
                <div className="bt">
                    <h2>{info.name}</h2>
                    <div className="text">
                        <span>{info.createtime_text}</span>
                        <span>作者：{info.nickname}</span>
                    </div>
                </div>
                <div className="wom_de" dangerouslySetInnerHTML={{__html:info.description}}>
                    
                </div>
                
                <div className="niming_sq niming_sq_xs_xq">
                    <a onClick={onCollection}> 
                         {info.collection_status === true ? <React.Icons.Star  /> : <React.Icons.StarO  />}
                            
                        {info.collection_status === true ? '已收藏' : '收藏'}
                    
                    </a>
                </div>
            </div>

            <div className="rem2"></div>
            <div className="shangxia_piab">
                <Prev />
                <Next />
            </div>
        </>
    )


}



export default Info;
