// 引入路由组件
import { BrowserRouter } from "react-router-dom";

// 引入总路由
import RouterList from "./routers";

function App() {
  return (
    <>
      <BrowserRouter>
        <RouterList />
      </BrowserRouter>
    </>
  );
}

export default App;
