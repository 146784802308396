import React from "react";

const Footer = () => {
    const location = React.Router.useLocation()
    const navigate = React.Router.useNavigate()

    const [active,setActive] = React.useState(location.pathname)

    const onTabBar = (name) => {
        setActive(name)
        navigate(name)
    }

    return (
        <>
            <div className="foot_menu">
                <React.Vant.Tabbar value={active} activeColor={'#ef6382'} onChange={onTabBar}>
                    <React.Vant.Tabbar.Item name="/" icon={<React.Icons.WapHomeO />}>首页</React.Vant.Tabbar.Item>
                    <React.Vant.Tabbar.Item name="/product/product/index"  icon={<React.Icons.AppsO  />}>分类</React.Vant.Tabbar.Item>
                    <React.Vant.Tabbar.Item name="/category/category/index"  icon={<React.Icons.Description  />}>学术</React.Vant.Tabbar.Item>
                    <React.Vant.Tabbar.Item name="/business/index" icon={<React.Icons.UserO  />}>我的</React.Vant.Tabbar.Item>
                </React.Vant.Tabbar>
            </div>
        
        </>
    )

}

export default Footer;