import React from 'react';
import Footer from './common/Footer';

const Home = () => {
    return (
        <>
            <div className="banner_shouy">{/* 调用组件 */}</div>

            {/* 快捷导航 */}
            <div className="shouye_kuanj">
                <div className="swiper-wrapper" style={{ display: 'flex' }}>
                    <div className="swiper-slide" style={{ width: '25%' }}>
                        <React.Router.NavLink to="/product/lease/add">
                            <img src="/assets/images/kj.png" alt="" />{' '}
                            <p>商品租赁</p>
                        </React.Router.NavLink>
                    </div>
                    <div className="swiper-slide" style={{ width: '25%' }}>
                        <a href="hsb.html">
                            <img src="/assets/images/kj1.png" alt="" />{' '}
                            <p>我要归还</p>
                        </a>
                    </div>
                    <div className="swiper-slide" style={{ width: '25%' }}>
                        <React.Router.NavLink to="/product/product/index">
                            <img src="/assets/images/kj2.png" alt="" />{' '}
                            <p>全部商品</p>
                        </React.Router.NavLink>
                    </div>
                    <div className="swiper-slide" style={{ width: '25%' }}>
                        <a href="#">
                            <img src="/assets/images/kj3.png" alt="" />{' '}
                            <p>合作医院</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="chan_p_center">
                <div className="chanp_k_sy">
                    <div className="sjpiue_chanp_list">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <a href="list_xq.html" className="a_blok">
                                    <img
                                        src="/assets/images/chanp_img.jpg"
                                        alt=""
                                    />{' '}
                                    <span>查看详情</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list_color">
                    <ul>
                        <li>
                            <img src="/assets/images/banner.jpg" alt="" />
                            <div className="right">
                                <p>
                                    人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...
                                </p>
                                <span>
                                    博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。
                                </span>
                            </div>
                        </li>
                        <li>
                            <img src="/assets/images/banner1.jpg" alt="" />
                            <div className="right">
                                <p>人的美丽会随着时间的流失 </p>
                                <span>
                                    博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。
                                </span>
                            </div>
                        </li>
                        <li>
                            <img src="/assets/images/anli.jpg" alt="" />
                            <div className="right">
                                <p>
                                    人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...
                                </p>
                                <span>
                                    博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。
                                </span>
                            </div>
                        </li>
                        <li>
                            <img src="/assets/images/anli1.jpg" alt="" />
                            <div className="right">
                                <p>
                                    人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...
                                </p>
                                <span>
                                    博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div style={{ height: '.75rem' }}></div>

            <Footer />
        </>
    );
};

export default Home;
