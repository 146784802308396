import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 引入react-vant
import * as Vant from 'react-vant';

// 引入react-vant-icons
import * as Icons from '@react-vant/icons';

// 引入react-router-dom
import * as Router from 'react-router-dom';

// 引入Cookies
import Cookies from 'react-cookies';

// 引入请求服务
import * as Request from './services/request'

// 定义全局属性
React.Vant = Vant;
React.Icons = Icons;
React.Router = Router;
React.Cookies = Cookies;
React.Http = Request;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
