import React from "react";

const Index = () => {
    let navigate = React.Router.useNavigate();
    const [keyword, setKeyword] = React.useState('');
    const [finished, setFinished] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [listCount,setListCount] = React.useState();
    const [list, setList] = React.useState([]);
    const [SearchParams, setSearchParams] = React.Router.useSearchParams();
    const [action, setAction] = React.useState(SearchParams.get("action"));

    const onRefresh = () => {
        setFinished(false);
        setPage(1);
        setList([]);

        return new Promise((resolve) => {
            setTimeout(() => {
                getProductData(true);
                resolve(true);
            }, 1500);
        });
    };

    const onLoadRefresh = (isRefresh) => {
        setFinished(true);
        getProductData(isRefresh);
    };

    const getProductData = async (isRefresh) => {
        let data = {
            page:isRefresh === true ? 1 : page,
            keyword:keyword,
            limit,
        };

        let result = await React.Http.POST({
            url: "/product/product/index",
            params: data,
        });

        if(result)
        {
            if(result.code === 0)
            {
                React.Vant.Toast.info(result.msg);
                setFinished(true);
                return;
            }

            if(isRefresh === true)
            {
                list.length = 0;
                React.Vant.Toast.info('刷新成功');
            }

            let listCount = result.data.count;
            setListCount(listCount);

            setList((v) => {
                const newList = isRefresh === true ? [] : [...v,...result.data.list];
                return newList;
            });

            if(list.length >= listCount)
            {
                React.Vant.Toast.info(result.msg);
                setFinished(true);
                return;
            }

            let Page = isRefresh === true ? 1 : page + 1;
            setPage(Page);
            setFinished(false);
        }

    };

    const onSearch = (value) =>
    {
        setKeyword(value);
        onRefresh();
    }

    const onSelected = (info) => {
        if (!info || JSON.stringify(info) === "{}") {
            React.Vant.Toast.fail("选择的商品不存在");
            return;
        }

        let data = {
            id:info.id,
            name:info.name,
            rent:info.rent,
            rent_price:info.rent_price
        }

        React.Cookies.save("product", data, { path: "/" });
        navigate(-1);
    };

    const Items = () => {
        if (list.length > 0) {
            if (action === "lease") {
                return list.map((item) => {
                    return (
                        <li key={item.id}>
                            <a onClick={(e) => onSelected(item)}>
                                <img src={item.thumb_cdn} alt="" />
                                <p>{item.name}</p>
                                <span>￥{item.rent_price}</span>
                            </a>
                        </li>
                    );
                });
            } else {
                return list.map((item) => {
                    return (
                        <li key={item.id}>
                            <React.Router.NavLink to={"/product/product/info?id=" + item.id}>
                                <img src={item.thumb_cdn} alt="" />
                                <p>{item.name}</p>
                                <span>￥{item.rent_price}</span>
                            </React.Router.NavLink>
                        </li>
                    );
                });
            }
        }
    };

    const onBack = () => {
        navigate(-1);
    };

    return (
        <>
            <React.Vant.NavBar
                title="产品列表"
                leftText="返回"
                fixed
                onClickLeft={onBack}
            />

            <div style={{ height: ".75rem" }}></div>

            <React.Vant.Search
                value={keyword}
                onSearch={onSearch}
                clearable
                placeholder="请输入搜索关键字"
            />

            <div className="left_kuangs">
                <React.Vant.PullRefresh onRefresh={onRefresh}>
                    {/* List 组件可以与PullRefresh组件结合使用，实现下拉刷新效果 */}
                    <React.Vant.List finished={finished} onLoad={onLoadRefresh}>
                        <ul>
                            <Items />
                        </ul>
                    </React.Vant.List>
                </React.Vant.PullRefresh>
            </div>
        </>
    );
};

export default Index;
