import Index from "../../components/product/order/Index";

const Order = [
    {
        path:'order/index',
        name:'OrderIndex',
        component:Index,
        auth:true
    }
];

export default Order;