import React from "react";

const Email = () => {
  const navigate = React.Router.useNavigate();

  const [business, setBusiness] = React.useState(
    React.Cookies.load("business") || {}
  );

  const [form] = React.Vant.Form.useForm();

  const [showCountDown, setShowCountDown] = React.useState(false);



  const onBack = () => {
    navigate(-1);
  };

  const sendSms = async () => {
    setShowCountDown(true);
    
    const res = await React.Http.POST({
        url:'/business/send',
        params:{email:business.email}
    })

    if(res && res.code === 1)
    {
        React.Vant.Toast({
            message:'验证码已发送至邮箱'
        })
    }else{
        React.Vant.Toast({
            message:res.msg
        })
    }

  };

  const SendBtn = () => {
    if (showCountDown) {
      return (
        <>
          <React.Vant.Button size="small" type="primary" >
            <React.Vant.CountDown time={60 * 1000} format="ss秒后重试" style={{color:"white"}}/>
          </React.Vant.Button>
        </>
      );
    } else {
      return (
        <React.Vant.Button size="small" type="primary" onClick={() => sendSms()}>
          发送
        </React.Vant.Button>
      );
    }
  };

  const FormFooter = () => {
    return (
      <>
        <div style={{ margin: "16px 16px 0" }}>
          <React.Vant.Button round nativeType="submit" type="primary" block>
            提交
          </React.Vant.Button>
        </div>
      </>
    );
  };


  const onSubmit = async values => {

    let data = {
        id:business.id,
        email:business.email,
        sms:values.sms,
    }

    const res = await React.Http.POST({
        url:'/business/email_auth',
        params:data
    })

    if(res && res.code === 1)
    {
        React.Vant.Toast({
            message:'验证成功'
        })
        navigate(-1)
    }else {
        React.Vant.Toast({
            message:res.msg
        })
        
    }

  };

  return (
    <>
      <React.Vant.Sticky>
        <React.Vant.NavBar
          title="邮箱认证"
          leftText="返回"
          onClickLeft={onBack}
        />
      </React.Vant.Sticky>

      <div className="my_kuang">
        <div className="bj_img">
          <img className="beij_s" src="/assets/images/my_beij.jpg" alt="" />
          <div className="nimetou_gaib">
            <div className="toux_hou">
              <img
                id="Dfgrg"
                style={{ height: "1.1rem" }}
                src={business.avatar_cdn}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <React.Vant.Form
        form={form}
        onFinish={onSubmit}
        footer={<FormFooter />}
        labelAlign={"right"}
        colon
      >
        <React.Vant.Form.Item
          label="邮箱"
          name="email"
          initialValue={business.email || ""}
        >
          <React.Vant.Input readOnly />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item label="验证码" name="sms" value="">
          <React.Vant.Input
            suffix={<SendBtn />}
            placeholder="请输入邮箱验证码"
          />
        </React.Vant.Form.Item>
      </React.Vant.Form>
    </>
  );
};

export default Email;
