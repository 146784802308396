import Index from "../../components/product/product/Index"

const Product = [
    {
        path:'product/index',
        name:'ProductIndex',
        component:Index
    },
    
];

export default Product;