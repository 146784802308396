import React from "react";
import Footer from "../common/Footer";


const SwiperItem = React.memo((props) => {
    if(props.recommendList.length > 0)
    {
        return (
            <React.Vant.Swiper>
                {props.recommendList.map((item) => (
                    <React.Vant.Swiper.Item key={item.id}>
                        <React.Vant.Image lazyload src={item.image_cdn} />
                    </React.Vant.Swiper.Item>
                ))}
            </React.Vant.Swiper>
        )
    }else{
        return (
            <></>
        )
    }
});


const Index = () => 
{
    let navigate = React.Router.useNavigate();

    // 上拉加载状态
    const [finished,setFinished] = React.useState(false);
    const [recommendList,setRecommendList] = React.useState([]);
    const [list,setList] = React.useState([]);
    const [page,setPage] = React.useState(1);
    const [limit,setLimit] = React.useState(10);
    const [count,setCount] = React.useState(0);

    React.useEffect(() => {
        getRecommendData();
    },[]);

    // 下拉刷新
    const onRefresh = () =>
    {
        setFinished(false);
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                await onLoadRefresh(true);
                resolve(true);
            },1500)
        })
    }

    // 上拉加载
    const onLoadRefresh = (isRefresh) =>
    {
        getCategoryData(isRefresh);
        setFinished(true);
    }

    const getCategoryData = async (isRefresh) => {
        let data = {
            page:isRefresh === true ? 1 : page,
            limit
        }

        let result = await React.Http.POST({
            url:'/category/category/index',
            params:data
        })

        if(result)
        {
            if(result.code === 0)
            {
                React.Vant.Toast.info(result.msg);
                setFinished(true);
                return;
            }

            if(isRefresh)
            {
                React.Vant.Toast.info('刷新成功');
                list.length = 0;
            }

            let ListCount = result.data.count;

            setList((v) => {
                const List = isRefresh === true ? [] : [...v,...result.data.list];
                return List;
            });

            setCount(result.data.count);

            if(list.length >= ListCount)
            {
                setFinished(true);
                return;
            }

            let Page = isRefresh === true ? 1 : page + 1;
            setPage(Page);
            setFinished(false);
        }
    }

    const Items = () => 
    {
        if(list.length > 0)
        {
            return list.map(item => {
                return (
                    <li key={item.id}>
                        <React.Router.NavLink to={'/category/category/info?id=' + item.id}>
                            <img src={item.image_cdn} alt="" />
                            <p>{item.name}</p>
                            <span>{item.createtime_text}</span>
                        </React.Router.NavLink>
                    </li>
                )
            })
        }
    }
    
    // 轮播图数据
    const getRecommendData = async () => {
        let result = await React.Http.POST({
            url:'/category/category/recommend',
            params: {}
        })

        if(result && result.code === 1)
        {
            setRecommendList(result.data);
        }
    }



    return (
        <>
            <React.Vant.NavBar
                title="学术"
                leftArrow={<></>}
            />

            <div className="banner_shouy">
                <SwiperItem recommendList={recommendList} />
            </div>

            <div className="lest_xs">
                <ul>
                    <React.Vant.PullRefresh onRefresh={onRefresh}>
                        {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                        <React.Vant.List finished={finished} onLoad={onLoadRefresh}>
                            <ul>
                                <Items />
                            </ul>
                        </React.Vant.List>
                    </React.Vant.PullRefresh>
                </ul>
            </div>

            <div style={{height:'.75rem'}}></div>

            <Footer />
        </>
    )
}


export default Index;